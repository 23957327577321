import React from 'react';

import Main from "../../layout/Main";
import Anchor from '../../components/atoms/Anchor';
import LastSection from "../../components/organs/lastsection/LastSection";
import MetaTags from '../../components/atoms/MetaTags';

import section2image from "../../assets/images/home-section-2.png";
import Customization from '../../assets/new-icons/Customization.svg';
import ReduceMaterialWastage from '../../assets/new-icons/Wastage.svg';
import FewerSpoolChanges from '../../assets/new-icons/Spool.svg';
import Traceability from '../../assets/new-icons/Traceability.svg';
import QualityControl from '../../assets/new-icons/Quality.svg';
import InventoryManagement from '../../assets/new-icons/Inventory.svg';
import GoodManufacturingPractices from '../../assets/new-icons/Manufacturing.svg';
import ConfidentialityAndBrandProtection from '../../assets/new-icons/Confidentiality.svg';
import HighStandardsOfHealthAndSafety from '../../assets/new-icons/Safety.svg';

import product1 from "../../assets/icons/product1.svg";
import product2 from "../../assets/icons/product2.svg";
import product3 from "../../assets/icons/product3.svg";
import product4 from "../../assets/icons/product4.svg";
import product5 from "../../assets/icons/product5.svg";
import product6 from "../../assets/icons/product6.svg";

import "./style.scss";

const Home = () => {

  return (
    <Main className="home-wrapper">

      <section className="section1">
        <div className="wrapper-1">
          <div className="content-align">
            <h2>Transforming Your Rollstock into Perfect Solutions</h2>
            <p>
              J.A.S.  Converting & Slitting delivers unmatched precision and fast turnaround times for all your converting and slitting  needs.
            </p>
            <div className="sec1but">
              <Anchor href="/contact-us" label="Get in Touch" />
              <Anchor href="/capabilities" className="b-anchor-secondary" label="Our Capabilities" />
            </div>
          </div>
        </div>
      </section>

      <section className="section2">
        <div className="wrapper-2">
          <div className="exp">
            <img src={section2image} alt="" className="sec2part1" />
            <div className="sec2part2">
              <h2>Delivering Quality Solutions Since 1980’s</h2>
              <p>
                Established in 1983 by John Austin Smith, our company began with a single vision and one machine. Since then, we’ve been at the forefront of precision converting and slitting services.
                Our commitment to quality, innovation, and customer satisfaction has made us a trusted partner for businesses across various industries.
              </p>
              <Anchor href="/about" label="Learn More about JAS" className='b-anchor-border' />
            </div>
          </div>
        </div>
      </section>

      <div className="section-3">
        <div className="wrapper">
          <div className="content">
            <h1>Benefits of Rewinding/ Slitting</h1>
            <p>
              We aim to get your job done right the first time and every time. Use our services to gain:
            </p>
          </div>
          <div className="boxes">
            <div className="row">
              <div className="box">
                <div className="icon">
                  <img src={Customization} alt="" />
                </div>
                <p>
                  Customization
                </p>
              </div>
              <div className="box">
                <div className="icon">
                  <img src={ReduceMaterialWastage} alt="" />
                </div>
                <p>
                  Reduce Material Wastage
                </p>
              </div>
              <div className="box">
                <div className="icon">
                  <img src={FewerSpoolChanges} alt="" />
                </div>
                <p>
                  Accuracy
                </p>
              </div>
            </div>
            <div className="row">
              <div className="box">
                <div className="icon">
                  <img src={Traceability} alt="" />
                </div>
                <p>
                  Traceability
                </p>
              </div>
              <div className="box">
                <div className="icon">
                  <img src={QualityControl} alt="" />
                </div>
                <p>
                  Quality Control
                </p>
              </div>
              <div className="box">
                <div className="icon">
                  <img src={InventoryManagement} alt="" />
                </div>
                <p>
                  Inventory Management
                </p>
              </div>
            </div>
            <div className="row">
              <div className="box">
                <div className="icon">
                  <img src={GoodManufacturingPractices} alt="" />
                </div>
                <p>
                  Buy Bulk to Reduce Cost
                </p>
              </div>
              <div className="box">
                <div className="icon">
                  <img src={ConfidentialityAndBrandProtection} alt="" />
                </div>
                <p>
                  Confidentiality and Brand Protection
                </p>
              </div>
              <div className="box">
                <div className="icon">
                  <img src={HighStandardsOfHealthAndSafety} alt="" />
                </div>
                <p>
                  High Standards of Health and Safety
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-4">
        <div className="wrapper">
          <div className="content">
            <h2>
              Want to find out more about our services?
            </h2>
            <p>
              Fill the  enquiry form and we will get in touch with you.
            </p>
            <Anchor href='/contact-us' label="Fill the enquiry form" />
          </div>
        </div>
      </div>

      <section className="section7" id='industries-section'>
        <div className="wrapper">
          <h2>Industries We Serve</h2>
          <p>
            J.A.S. Converting & Slitting caters to a wide range of industries, tailoring our services to meet the unique requirements of each sector.
            We understand the specific needs of
          </p>
          <div className="sec5part1">
            <div className="sec-5-card-row-1">
              <div className="card">
                <div>
                  <img src={product1} alt="" />
                </div>
                <h5>Flexible Packaging</h5>
              </div>
              <div className="card">
                <div>
                  <img src={product2} alt="" />
                </div>
                <h5>Industrial Paper Products</h5>
              </div>
              <div className="card">
                <div>
                  <img src={product3} alt="" />
                </div>
                <h5>Building & Construction</h5>
              </div>
            </div>

            <div className="sec-5-card-row-2">
              <div className="card">
                <div>
                  <img src={product4} alt="" />
                </div>
                <h5>Food Processing & Handling</h5>
              </div>
              <div className="card">
                <div>
                  <img src={product5} alt="" />
                </div>
                <h5>E-commerce</h5>
              </div>
              <div className="card">
                <div>
                  <img src={product6} alt="" />
                </div>
                <h5>General Industrial</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <LastSection
        backgroundImage="../../assets/images/home-last-section.png"
        positionX={-33.763}
        positionY={-13.883}
        sizeW={68.639}
        sizeH={104.4}
      />

      <MetaTags
        title="Transforming Your Rollstock into Perfect Solutions - J.A.S. Converting & Slitting"
        description="J.A.S. Converting & Slitting delivers unmatched precision and fast turnaround times for all your converting and slitting needs, ensuring your rollstock is transformed into perfect solutions."
        keywords="J.A.S. Converting, slitting, converting services, rollstock converting, precision converting, fast turnaround, converting solutions, slitting solutions"
        robots="index, follow"
      />
    </Main>
  );
};

export default Home;
