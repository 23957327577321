import Routes from './Routes';
import React from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function App() {
  return (
    <div className="App">
      <Routes />      
      <NotificationContainer />
    </div>
  );
}

export default App;
