import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";
import logo from "../../../assets/icons/logo.svg";
import mail from "../../../assets/icons/mail.svg";
import phone from "../../../assets/icons/phone-call.svg";

const Footer = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#industries-section' && location.pathname === "/") {
      const section = document.getElementById('industries-section');
      if (section) {
        const headerOffset = -200;
        const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = sectionPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [location]);

  const handleIndustriesClick = (event) => {
    event.preventDefault();
    if (location.pathname !== "/") {
      window.location.href = "/#industries-section";
    } else {
      const section = document.getElementById('industries-section');
      if (section) {
        const headerOffset = 100;
        const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = sectionPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  return (
    <footer>
      <div className="footer-wrapper">
        <div className="footer-main">
          <div className="footer-content-1">
            <div className="footer-img">
              <Link to="/"><img src={logo} alt="Jas Logo" /></Link>
            </div>
            <div className="footer-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/capabilities">Capabilities</Link>
                </li>
                <li>
                  <a href="#industries-section" onClick={handleIndustriesClick}>Industries</a>
                </li>
                <li>
                  <Link to="/contact-us">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-content-2">
            <div className="footer-phone">
              <div className="circle">
                <img src={phone} alt="Phone" />
              </div>
              <div>
                <p>Call us at</p>
                <a href="tel:0123456789"><div className="number">03 9772 2455</div></a>
              </div>
            </div>
            <div className="footer-mail">
              <div className="circle">
                <img src={mail} alt="Mail" />
              </div>
              <div>
                <p>Email us at:</p>
                <a href="mailto:info@jasconverting.com.au"><div className="mail-id">info@jasconverting.com.au</div></a>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div></div>
          <p>Copyright 2024  J.A.S. Converting & Slitting</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
