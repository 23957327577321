import React, {Fragment} from "react";

import Header from "../../components/organs/header/index";
import Footer from "../../components/organs/footer/index";
import "./styles.scss";

const Main = ({children, className, active }) => {
    return (
        <Fragment>
            <Header active={active} />
            <main className={`v-main ${className}`}>
                {children}
            </main>
            <Footer />
        </Fragment>
    );
}

export default Main;