import moment from "moment";

const getDate = (date) => {
    if(date) {
        return moment(date).format('MMM DD, YYYY')
    }
    return date
}

const getRupee = (val) => {
    if(typeof(val) === "number") {
        return `₹${parseInt(val).toFixed(2)}`
    }
    return val
}

const getCurrentUser = () => {
    let currentUser = localStorage.getItem('currentUser');
    if(currentUser && currentUser !== "undefined") {
      currentUser = JSON.parse(currentUser);
    }
    return currentUser
}

const getCurrentFinancialYear = () => {
    let currentFinancialYear = localStorage.getItem('currentFinancialYear');
    if(currentFinancialYear && currentFinancialYear !== "undefined") {
      currentFinancialYear = JSON.parse(currentFinancialYear);
    }
    return currentFinancialYear
}

const getCurrentUserImage = () => {
    return localStorage.getItem("currentUserImage")
}

const getAdmissionYear = () => {
    return localStorage.getItem("admissionYear")
}

const getSelectedAcademicYear = () => {
    return localStorage.getItem("selectedAcademicYear");
}

const getStudentInstanceData = () => {
    let studentInstanceData = localStorage.getItem('studentInstanceData');
    if(studentInstanceData && studentInstanceData !== "undefined") {
      studentInstanceData = JSON.parse(studentInstanceData);
    }
    return studentInstanceData
}

const getAdmissionSession = (date) => {
    if(date) {
        let newDate = moment(date).format('YYYY');
        newDate = Number(newDate);
        return `${newDate}-${newDate+1}`
        
    }
    return date
}

const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

const validatePhoneNumber = (phoneNumber) => {
    return phoneNumber.match(
      /^\+?([ -]?\d+)+|\(\d+\)([ -]\d+)$/
    );
}


export { getDate, getRupee, getCurrentUser, getCurrentFinancialYear, getCurrentUserImage, getAdmissionYear, getSelectedAcademicYear, getStudentInstanceData, getAdmissionSession, getQueryParam, validateEmail, validatePhoneNumber };