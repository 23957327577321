import { useEffect, useState } from "react";
import { getRequest } from "../../utils/axios";

import InnerHTML from 'dangerously-set-html-content';
import Loader from "../../components/atoms/Loader";

import { useParams } from "react-router-dom";

const DynamicPage  = () => {
    const { page } = useParams();

    const [loading, setLoading] = useState(false);
    const [pageHtml, setPageHtml] = useState('');

    useEffect(() => {
        getPage()
    }, [])

    const getPage = async () => {
        setLoading(true);
        const resp = await getRequest(`PageComponent/GetPageComponentsByPage/Pages/${page ? page : 'index'}`);

        let html = '';
        resp?.data?.forEach(r => {
            html += r.componentHTML 
        })

        setPageHtml(html);
        setLoading(false);
    }

    return (
        <div>
            <Loader loading={loading} />
            {pageHtml ? (
                <InnerHTML html={pageHtml} />
            ) : (
                "Not Found"
            )}
        </div>
    )
};

export default DynamicPage;