import React, { useRef } from 'react';

import Main from '../../layout/Main';
import Anchor from "../../components/atoms/Anchor";
import MetaTags from '../../components/atoms/MetaTags';

import OwnerImage from "../../assets/images/owner-image.png";
import OwnerImage1 from "../../assets/images/owner-image-1.jpg";
import section4image from "../../assets/images/about-section-4.jpeg";
import icon1 from "../../assets/icons/about-sec-3-icon-1.svg";
import icon2 from "../../assets/icons/about-sec-3-icon-2.svg";
import icon3 from "../../assets/icons/about-sec-3-icon-3.svg";
import LastSection from "../../components/organs/lastsection/LastSection";

import "./styles.scss";

const About = () => {
    const section2Ref = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <Main className="about-wrapper">

            <section className="section-1">
                <div className="section-1-wrapper">
                    <div className="section-1-content">
                        <div className="left-content">
                            <p>Since 1980’s</p>
                            <h2>Leaders in <br />
                                Converting & Slitting</h2>
                        </div>
                        <div className="right-content">
                            <p>Through our craft and unwavering commitment to our core values of accuracy, reliability, and quality service , we have grown into an industry leader in converting and slitting operations.
                            </p>
                            <Anchor label="Read More" className='b-anchor-width' onClick={() => scrollToSection(section2Ref)} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-2" ref={section2Ref}>
                <div className="section-2-wrapper">
                    <div className='heading'>
                        About J.A.S.  Converting & Slitting
                    </div>
                    <div className='content'>

                        <div className='left-side'>

                            <div className='image'>
                                <img src={OwnerImage1} alt='image-1' />
                            </div>

                            <div className='data'>
                                <p>
                                    J.A.S. Converting & Slitting began its journey in 1983 with a simple vision and a single machine, spearheaded by John Austin Smith supported by his wife Aileen Smith and son Graeme Smith.
                                    From those initial steps and support of some incredible staff members, the services were carried forward by the tireless efforts of Michelle and Russell O'Toole.
                                </p>
                                <p>
                                    Michelle, with her keen eye for detail, ensured that precision was woven into every job.
                                    She understood that each roll leaving our facility carried not just dimensions but also a promise—to elevate our clients' businesses.
                                </p>
                                <p>
                                    Russell, the quiet force behind the scenes, fine-tuned processes, and kept the wheels turning.
                                    His dedication to reliability meant that deadlines were met, even when challenges loomed large.
                                </p>
                            </div>
                        </div>

                        <div className='right-side'>

                            <div className='image'>
                                <img src={OwnerImage} alt='image' />
                            </div>

                            <div className='data-1'>
                                <p>
                                    And now, as the sun sets on one chapter, a new dawn awaits. Ritu and Abbi, the torchbearers of this legacy bring fresh perspectives, a hunger for progress, and a deep respect for what came before.
                                </p>
                                <p>
                                    Our team understand that your success depends on our ability to deliver consistently, and we take that responsibility seriously.
                                    To our clients, partners, and fellow dreamers: Join us. Explore our services, witness the legacy, and let's create new chapters together.
                                    At J.A.S. Converting & Slitting, the story never ends—it evolves.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="section-3">
                <div className="section-3-wrapper">
                    <div className="sec-3-heading">
                        <h2>Our Core Values</h2>
                    </div>
                    <div className="sec-3-boxes">

                        <div className="box">
                            <div className="box-content">
                                <div className="icon">
                                    <img src={icon1} alt="accuracy-icon" />
                                </div>
                                <h3>Accuracy</h3>
                                <p>We take meticulous pride in achieving precise results that meet your exact specifications</p>
                            </div>
                        </div>

                        <div className="box">
                            <div className="box-content">
                                <div className="icon">
                                    <img src={icon2} alt="reliability-icon" />
                                </div>
                                <h3>Reliability</h3>
                                <p>You can depend on us to deliver your projects on time, every time.</p>
                            </div>
                        </div>

                        <div className="box">
                            <div className="box-content">
                                <div className="icon">
                                    <img src={icon3} alt="quality-icon" />
                                </div>
                                <h3>Quality Service</h3>
                                <p>Our dedicated team is committed to exceeding your expectations with exceptional customer service.</p>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

            <section className="section-4">
                <div className="sec-4-wrapper">
                    <div className="sec-4-content">
                        <h2>Our Approach</h2>
                        <p>
                            At J.A.S. Converting & Slitting, we believe in a collaborative approach. We work closely with you to understand your specific converting needs and objectives.
                            Whether it's receiving your products, following detailed specifications, maintaining accurate finished sizes, adhering to labeling requirements, or ensuring proper recordkeeping and palletization for dispatch, we handle it all with precision and care.
                        </p>
                    </div>
                    <div className="sec-4-image">
                        <img src={section4image} alt="approach-icon" />
                    </div>
                </div>
            </section>

            <LastSection />

            <MetaTags 
                title="J.A.S. Converting & Slitting - Precision, Dedication, and Legacy Since 1983"
                description="Founded in 1983 by John Austin Smith, J.A.S. Converting & Slitting has grown with the support of dedicated staff like Michelle and Russell O'Toole, ensuring precision and reliability in every job."
                keywords="J.A.S. Converting, slitting, converting services, precision, reliability, Michelle O'Toole, Russell O'Toole, John Austin Smith, Aileen Smith, Graeme Smith, converting industry, family business, rollstock converting, slitting solutions, 1983"
            />

        </Main>
    )

}

export default About;