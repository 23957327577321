import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";

import Anchor from '../../atoms/Anchor';
import logo from "../../../assets/icons/logo-header.svg";
import hamburger from "../../../assets/icons/hamebuger-icon.png";

import "./style.scss";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const scrollToSection = () => {
      if (location.hash === '#industries-section' && location.pathname === "/") {
        const section = document.getElementById('industries-section');
        if (section) {
          const headerOffset = 20; 
          const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = sectionPosition - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      }
    };
    setTimeout(scrollToSection, 100);
  }, [location]);

  const toggleMobileMenu = () => setMobileMenuOpen(prevState => !prevState);

  const handleIndustriesClick = (event) => {
    event.preventDefault();
    if (location.pathname !== "/") {
      navigate('/#industries-section');
    } else {
      const section = document.getElementById('industries-section');
      if (section) {
        const headerOffset = 20;
        const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = sectionPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const navLinks = (
    <ul>
      <li><Link to="/" className={getNavLinkClass('/')}>Home</Link></li>
      <li><Link to="/about" className={getNavLinkClass('/about')}>About</Link></li>
      <li><Link to="/capabilities" className={getNavLinkClass('/capabilities')}>Capabilities</Link></li>
      <li>
        <a href="#industries-section" className={location.hash === '#industries-section' ? 'active' : ''} onClick={handleIndustriesClick}>Industries</a>
      </li>
      <li>
        <Anchor label="Contact" href="/contact-us"/>
      </li>
    </ul>
  );

  return (
    <header>
      <div className="header-wrapper">
        <div className="left">
          <Link to="/"><img className="logo" src={logo} alt="Jas Logo" /></Link>
        </div>
        <div className="right desktop">
          <nav>{navLinks}</nav>
        </div>
        <div className="right mobile">
          <button className="request-demo">Contact</button>
          <button className="hamburger-icon" onClick={toggleMobileMenu} aria-label="Menu">
            <img src={hamburger} alt="Menu" />
          </button>
          <div className={`mobile-menu ${mobileMenuOpen ? 'open' : 'hidden'}`}>
            <button className="close-btn" onClick={toggleMobileMenu} aria-label="Close Menu">X</button>
            {navLinks}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
