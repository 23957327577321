import React, { useState } from 'react';
import { postRequest } from "../../utils/axios";
import { validateEmail, validatePhoneNumber } from "../../utils/utils";
import { HashLoader } from 'react-spinners';

import Main from '../../layout/Main';
import ErrorMsg from "../../components/atoms/ErrorMsg";
import MetaTags from '../../components/atoms/MetaTags';

import phone from "../../assets/icons/phone.svg";
import email from "../../assets/icons/mail.svg";
import star from "../../assets/icons/star.svg";
import upload from "../../assets/icons/blue-upload.svg";

import "./styles.scss";

const ContactUs = () => {

    const emptyData = {
        fullName: '',
        company: '',
        businessEmail: '',
        phoneNo: '',
        description: '',
        attachment: null
    };

    const errorObj = {
        fullName: '',
        company: '',
        businessEmail: '',
        phoneNo: '',
        description: '',
        attachment: ''
    };

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(emptyData);
    const [errors, setErrors] = useState(errorObj);
    const [success, setSuccess] = useState(false);

    const handleClose = () => {
        setErrors(errorObj);
        setData(emptyData);
        setSuccess(false);
    };

    const handleChange = (value, field) => {
        setData(prev => ({ ...prev, [field]: value }));
        if (errors[field] && value.trim() !== '') {
            setErrors(prev => ({ ...prev, [field]: '' }));
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setData(prev => ({ ...prev, attachment: file }));
    };

    const handleValidation = () => {
        const errorObj = {};
        let validated = false;
        if (data.fullName === '') {
            errorObj.fullName = 'Please enter your full name';
            validated = true;
        } else {
            errorObj.fullName = '';
        }

        if (data.company === '') {
            errorObj.company = 'Please enter your company name';
            validated = true;
        } else {
            errorObj.company = '';
        }

        if (data.businessEmail === '') {
            errorObj.businessEmail = 'Please enter your email';
            validated = true;
        } else if (!validateEmail(data.businessEmail)) {
            errorObj.businessEmail = 'Please enter valid email';
            validated = true;
        } else {
            errorObj.businessEmail = '';
        }

        if (data.phoneNo === '') {
            errorObj.phoneNo = 'Please enter phone no.';
            validated = true;
        } else if (!validatePhoneNumber(data.phoneNo)) {
            errorObj.phoneNo = 'Please enter valid phone no.';
            validated = true;
        }

        if (data.description === '') {
            errorObj.description = 'Please enter description';
            validated = true;
        } else {
            errorObj.description = '';
        }

        setErrors({ ...errors, ...errorObj });

        return !validated;
    };

    const handleSubmit = async () => {
        const validated = handleValidation();
        if (!validated) return;

        const postObj = {
            FirstName: data.fullName,
            Company: data.company,
            Email: data.businessEmail,
            PhoneNumber: data.phoneNo,
            Description: data.description,
            MailType: 'ContactUs',
            Attachment: data.attachment
        };
        console.log(postObj);

        setLoading(true);
        const resp = await postRequest(`Contact/ContactUs`, postObj);
        if (resp.status === 200) {
            handleClose();
            setSuccess(true);
        }

        setLoading(false);
    };

    const handleNameKeyPress = (event) => {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode >= 48 && charCode <= 57) {
            event.preventDefault();
        }
    };

    const handlePhoneKeyPress = (event) => {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const handlePhoneChange = (value) => {
        if (value.length <= 15) {
            setData(prev => ({ ...prev, phoneNo: value }));
            if (errors.phoneNo && value.trim() !== '') {
                setErrors(prev => ({ ...prev, phoneNo: '' }));
            }
        }
    };

    return (
        <Main className="contact-us-wrapper">
            <section className="section-1">
                <div className="section-wrapper">
                    <div className="section-1-part">
                        <div className="left-content">
                            <h2>Contact Converting & Slitting Experts</h2>
                        </div>
                        <div className="right-content">
                            <div className="phone">
                                <div className="content">
                                    <span><img src={phone} alt="" /></span>
                                    <div className="contact-detail">
                                        <p className="text">Call us at</p>
                                        <a href="tel:0123456789"><p className="detail">03 9772 2455</p></a>
                                    </div>
                                </div>
                            </div>
                            <div className="mail">
                                <div className="content">
                                    <span><img src={email} alt="" /></span>
                                    <div className="contact-detail">
                                        <p className="text">Email us at:</p>
                                        <a href="mailto:info@jasconverting.com.au"><p className="detail">info@jasconverting.com.au</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-2">
                <div className="section-2-wrapper">
                    <div className='map'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d363184.1734884988!2d144.70804538338692!3d-38.077680718805816!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad66d54a8678963%3A0xe014303aba1e0c!2sJ.A.S.%20Converting%20%26%20Slitting!5e0!3m2!1sen!2sin!4v1717586025452!5m2!1sen!2sin"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                        <div className="map-overlay"></div>
                    </div>

                    {!success ? (
                        <div className="form-here">
                            {loading && (
                                <div className="backdrop">
                                    <HashLoader color="#3460db" loading={loading} size={50} />
                                </div>
                            )}
                            <form>
                                <div className='input-data'>
                                    <label>Full name <img src={star} alt="required" /></label>
                                    <input
                                        placeholder="Enter full name"
                                        value={data.fullName}
                                        onChange={(e) => { handleChange(e.target.value, "fullName") }}
                                        onKeyPress={handleNameKeyPress}
                                    />
                                    {errors.fullName && (
                                        <ErrorMsg>{errors.fullName}</ErrorMsg>
                                    )}
                                </div>

                                <div className='input-data'>
                                    <label>Company <img src={star} alt="required" /></label>
                                    <input
                                        placeholder="Your company name"
                                        value={data.company}
                                        onChange={(e) => { handleChange(e.target.value, "company") }}
                                        onKeyPress={handleNameKeyPress}
                                    />
                                    {errors.company && (
                                        <ErrorMsg>{errors.company}</ErrorMsg>
                                    )}
                                </div>

                                <div className='input-data'>
                                    <label>Email <img src={star} alt="required" /></label>
                                    <input
                                        placeholder="name@company.com"
                                        value={data.businessEmail}
                                        onChange={(e) => { handleChange(e.target.value, "businessEmail") }}
                                    />
                                    {errors.businessEmail && (
                                        <ErrorMsg>{errors.businessEmail}</ErrorMsg>
                                    )}
                                </div>

                                <div className='input-data'>
                                    <label>Phone <img src={star} alt="required" /></label>
                                    <input
                                        placeholder="+1254 215 021"
                                        value={data.phoneNo}
                                        onChange={(e) => { handlePhoneChange(e.target.value) }}
                                        onKeyPress={handlePhoneKeyPress}
                                    />
                                    {errors.phoneNo && (
                                        <ErrorMsg>{errors.phoneNo}</ErrorMsg>
                                    )}
                                </div>

                                <div className='input-data'>
                                    <label>Attachment</label>
                                    <div className="attachment-wrapper">
                                        <input
                                            placeholder='Click to upload file'
                                            onChange={handleFileChange}
                                            id="file-input"
                                            readOnly
                                        />
                                        <label htmlFor="file-input">
                                            <img src={upload} alt="upload icon" className="upload-icon" />
                                        </label>
                                    </div>
                                </div>

                                <div className='input-data'>
                                    <label>How can we help? <img src={star} alt="required" /></label>
                                    <textarea
                                        placeholder="Tell us how we can help you..."
                                        value={data.description}
                                        rows="10"
                                        cols="40"
                                        onChange={(e) => { handleChange(e.target.value, "description") }}
                                    />
                                    {errors.description && (
                                        <ErrorMsg>{errors.description}</ErrorMsg>
                                    )}
                                </div>

                                <button onClick={handleSubmit} type='button'>Send Enquiry</button>
                            </form>
                        </div>
                    ) : (
                        <div className='b-success-wrapper'>
                            <p>
                                Your request is submitted successfully. Our team will contact you shortly.
                                <br />
                                <button onClick={() => setSuccess(false)} type='button'>Send Another Enquiry</button>
                            </p>
                        </div>
                    )}
                </div>
            </section>

            <MetaTags
                title="Contact Converting & Slitting Experts | J.A.S. Converting & Slitting"
                description="Contact J.A.S. Converting & Slitting experts for all your converting and slitting needs. Call us at 03 9772 2455 or email us at info@jasconverting.com.au."
                keywords="contact J.A.S. Converting, contact slitting experts, converting services contact, call J.A.S. Converting, email J.A.S. Converting, converting solutions, slitting solutions contact"
            />
        </Main>
    );
}

export default ContactUs;
