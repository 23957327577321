import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";
import Home from './pages/home/index';
import About from './pages/about';
import ContactUs from './pages/contactus';
import Capabilities from './pages/capabilities/index.js';
import DynamicPage from './pages/dynamicPage/index.js';

const router = createBrowserRouter([
    {
        path:"/",
        element:<Home/>
    },
    {
        path:"*",
        element: <Navigate to="/" replace />
    },
    {
        path:"/about",
        element:<About/>
    },
    {
        path:"/contact-us",
        element:<ContactUs/>
    },
    {
        path:"/capabilities",
        element:<Capabilities/>
    },
    {
        path:"/pages/:page",
        element: <DynamicPage />
    },
    {
        path:"/pages/",
        element: <DynamicPage />
    },
])

const Routes = () => {
    return (
        <RouterProvider router={router} ></RouterProvider>
    );
  }
   
export default Routes;
