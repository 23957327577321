import React from "react";
import "./LastSection.scss";
import { useLocation } from "react-router-dom";
import Anchor from "../../atoms/Anchor";

const LastSection = () => {
  const location = useLocation();
  const backgroundClass = getBackgroundClass(location.pathname);

  function getBackgroundClass(pathname) {
    switch (pathname) {
      case '/':
        return 'background-home';
      case '/about':
        return 'background-about';
      case '/products-services':
        return 'background-product';
      case '/capabilities':
        return 'background-capabilities';
      default:
        return '';
    }
  }

  const handleAboutClick = (event) => {
    if (location.pathname === '/about') {
      event.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className={`last-section-wrapper ${backgroundClass}`}>
      <div className="last-wrapper">
        <div className="last-sec-content">
          <div className="edit">
            <h2>Need Cutting & Slitting solutions?</h2>
            <div className="duo">
              <span></span>
              <p>
                Ready to experience the J.A.S. Converting & Slitting difference? Contact us today to discuss your project requirements
              </p>
            </div>
            <div className="links">
              <Anchor label="Contact Us" href="/contact-us"/>
              <Anchor label="About Us"  href="/about" className="b-anchor-border" onClick={handleAboutClick}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LastSection;
