import React, { useState } from 'react';

import Main from "../../layout/Main";
import Anchor from '../../components/atoms/Anchor';
import LastSection from "../../components/organs/lastsection/LastSection";
import MetaTags from '../../components/atoms/MetaTags';

import qualityStandards from "../../assets/images/quality-standards.png";

import Precision from "../../assets/new-icons/Precision.svg";
import Trained from "../../assets/new-icons/Workforce.svg";
import Custom from "../../assets/new-icons/Slitting.svg";
import Rewinding from "../../assets/new-icons/Rewinding.svg";
import Packaging from "../../assets/new-icons/Packaging.svg";
import Quality from "../../assets/new-icons/Quality.svg";
import Design from "../../assets/new-icons/Support.svg";
import Storage from "../../assets/new-icons/Transport.svg";
import arrowDown from "../../assets/new-icons/chevron-down.svg";
import crushed from "../../assets/new-icons/crushed.svg";

import "./style.scss";

const Products = () => {

  const [openAccordion, setOpenAccordion] = useState(0);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const accordionData = [
    {
      heading: "Material Winding",
      content: "Converted rolls maintain the original quality of the parent roll's outer surface, unless otherwise specified by the customer."
    },
    {
      heading: "Slit Width Tolerance",
      content: "All slit widths maintain a tolerance of ±1mm for exceptional precision (unless otherwise agreed upon)."
    },
    {
      heading: "Roll Winding and Geometry",
      content: "Rolls are evenly wound with the appropriate tension to create optimal slit roll geometry. This prevents web distortion, starring, binding, telescoping, cinching, or raised beads/edges."
    },
    {
      heading: "Stepping and Protruding Winds",
      content: "Minimized stepping and protruding winds, adhering to the highest industry standards. Excessively protruded winds exceeding 1mm are not acceptable."
    },
    {
      heading: "Core Size and Finishing",
      content: "Core size is determined by your specifications and sits flush with the slit roll edges. Joins are overlapped and double taped unless otherwise stipulated."
    },
    {
      heading: "Slit Roll Specifications",
      content: "Outer diameter (OD) or length falls within +/-5% of the requested size or meterage. Roll weights are provided net of core weight and rounded to the nearest 0.1kg. When specified, maximum roll weights or OD limits are not exceeded."
    },
    {
      heading: "Roll Edge Quality",
      content: "Clean, professional cuts on all roll edges, free from nicks, marks, or 'feathering.' Roll edges are essentially flat and consistent, without dishing."
    },
  ];

  return (
    <Main className="product-wrapper">

      <section className="section1">
        <div className="wrapper">
          <div className="content">
            <div className="sec1p1">
              <h1>Products and Services - Tailored to Your Needs</h1>
            </div>
            <div className="sec1p2">
              <p>
                At J.A.S.  Converting & Slitting, we specialize in high-precision converting and slitting for a wide range of materials.
                We utilize cutting-edge technology and our extensive knowledge to ensure optimal results for your specific needs.
              </p>
              <Anchor label="Read More" className='b-anchor-width' href="/about" />
            </div>
          </div>
        </div>
      </section>

      <div className="section-2">
        <div className="wrapper">
          <div className="content">
            <h2>
              Capabilities
            </h2>
          </div>
          <div className="boxes">

            <div className="row">

              <div className="box">
                <div className="icon">
                  <img src={Precision} alt="Precision" />
                </div>
                <div className="data">
                  <h4>
                    State-of-the-art Precision Equipment
                  </h4>
                  <p>
                    Innovative technology that ensures exact specifications.
                  </p>
                </div>
              </div>

              <div className="box">
                <div className="icon">
                  <img src={Trained} alt="Trained" />
                </div>
                <div className="data">
                  <h4>
                    Trained Workforce
                  </h4>
                  <p>
                    Skilled operators who carry our values of precision and dedication into every task.
                  </p>
                </div>
              </div>

              <div className="box">
                <div className="icon">
                  <img src={Custom} alt="Custom" />
                </div>
                <div className="data">
                  <h4>
                    Custom Slitting
                  </h4>
                  <p>
                    Innovative technology that ensures exact specifications.
                  </p>
                </div>
              </div>

            </div>

            <div className="row">

              <div className="box">
                <div className="icon">
                  <img src={Rewinding} alt="Rewinding" />
                </div>
                <div className="data">
                  <h4>
                    Rewinding
                  </h4>
                  <p>
                    Roll-to-roll rewinding services to get correct length as well as reverse incorrect print/rewind direction across various paper and film products.
                  </p>
                </div>
              </div>

              <div className="box">
                <div className="icon">
                  <img src={Packaging} alt="Packaging" />
                </div>
                <div className="data">
                  <h4>
                    Custom Packaging
                  </h4>
                  <p>
                    Tailored packaging services for finished paper and film products, including labeling and branding options.
                  </p>
                </div>
              </div>

              <div className="box">
                <div className="icon">
                  <img src={Quality} alt="Quality" />
                </div>
                <div className="data">
                  <h4>
                    Quality Assurance
                  </h4>
                  <p>
                    Comprehensive quality checks and assurance to meet industry standards and customer specifications.
                  </p>
                </div>
              </div>

            </div>

            <div className="row">

              <div className="box">
                <div className="icon">
                  <img src={Design} alt="Design" />
                </div>
                <div className="data">
                  <h4>
                    Design Support
                  </h4>
                  <p>
                    Collaborative support for designing and optimizing materials for converting processes.
                  </p>
                </div>
              </div>

              <div className="box">
                <div className="icon">
                  <img src={Storage} alt="Storage" />
                </div>
                <div className="data">
                  <h4>
                    Storage and Transport Services
                  </h4>
                  <p>
                    Safe storage and transportation services, with optional production run records traceable to the source.
                  </p>
                </div>
              </div>

              <div className="box">
                <div className="icon">
                  <img src={crushed} alt="Storage" />
                </div>
                <div className="data">
                  <h4>
                    Fixing Crushed or Damaged Cores
                  </h4>
                  <p>
                    Wherever possible we can rewind rolls with damaged cores to high-quality ones that can withstand the pressures of winding and handling.
                  </p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div className="section-3">
        <div className="wrapper">
          <div className="content">
            <h2>
              Materials
            </h2>
          </div>
          <div className="boxes">

            <div className="row">
              <div className="box">
                Commercial Print / Digital/ Specialty Paper
              </div>
              <div className="box">
                Kraft Paper / Counter Rolls
              </div>
              <div className="box">
                Co-extruded Laminates
              </div>
            </div>

            <div className="row">
              <div className="box">
                Plain / Printed / Laminated Films
              </div>
              <div className="box">
                Vinyl
              </div>
              <div className="box">
                Flexible / Semi-rigid Packaging Material
              </div>
            </div>

            <div className="row">
              <div className="box">
                Self-adhesives
              </div>
              <div className="box">
                Label Stock
              </div>
              <div className="box">
                Double Sided Tapes
              </div>
            </div>

            <div className="row">
              <div className="box">
                PVC / PET / BOPP / CTPP
              </div>
              <div className="box">
                Magnetic Rolls
              </div>
              <div className="box">
                Insulation Tapes
              </div>
            </div>

            <div className="row">
              <div className="box">
                Shrink Wrap Films
              </div>
              <div className="box">
                Supported Foil
              </div>
              <div className="box">
                Fabric / Lint Free Wash Cloths
              </div>
            </div>

            <div className="row">
              <div className="box">
                Woven Material
              </div>
              <div className="box">
                Nonwoven Material
              </div>
              <div className="box">
                Canvas Rolls
              </div>
            </div>

            <div className="row">
              <div className="box">
                Flagging Tapes
              </div>
              <div className="box">
                Strip Feed Paper
              </div>
              <div className="box">
                Medical Grade Material
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="section-4">
        <div className="wrapper">
          <div className="content">
            <div className="left-side">
              <h2>Quality Standards</h2>
              <div className="accordions">
                {accordionData.map((item, index) => (
                  <div
                    key={index}
                    className={`accordion ${openAccordion === index ? "open" : ""}`}
                  >
                    <div
                      className="heading"
                      onClick={() => toggleAccordion(index)}
                    >
                      <h4>{item.heading}</h4>
                      <img
                        src={arrowDown}
                        alt="arrowDown"
                        className={`arrow ${openAccordion === index ? "rotated" : ""}`}
                      />
                    </div>
                    <div className="content">
                      <p>{item.content}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="right-side">
              <img src={qualityStandards} alt="Quality Standards" />
            </div>
          </div>
        </div>
      </div>

      <LastSection />

      <MetaTags
        title="Products and Services - Tailored to Your Needs | J.A.S. Converting & Slitting"
        description="J.A.S. Converting & Slitting offers high-precision converting and slitting services for various materials, supported by state-of-the-art equipment, custom solutions, quality assurance, and dedicated design support."
        keywords="J.A.S. Converting, slitting, precision converting, custom slitting, rewinding services, packaging, quality assurance, design support, storage services, commercial print, digital paper, specialty paper, laminates, vinyl, flexible packaging, self-adhesives, tapes, shrink wrap, magnetic rolls, woven material, medical-grade material, flexible packaging, industrial materials, flagging tapes, PVC, PET, BOPP"
      />

    </Main>
  );
};

export default Products;
