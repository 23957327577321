import React from 'react';
import { PropTypes } from "prop-types";

import './styles.scss';

const ErrorMsg = ({className, children, ...rest}) => {
  return (
    <div
        className={`v-errorMsg ${className}`}
        {...rest}
    >
      {children}
    </div>
  );
}

ErrorMsg.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
}

ErrorMsg.defaultProps = {
    className: '',
    children: <></>
}

export default ErrorMsg;