import React from 'react';
import './styles.scss';

const Anchor = ({ label, href, target = '_self', className = '', onClick }) => {
    return (
        <a
            href={href}
            target={target}
            className={`b-anchor-primary ${className}`}
            onClick={onClick}
            rel={target === '_blank' ? 'noopener noreferrer' : ''}
        >
            {label}
        </a>
    );
};

export default Anchor;
